import {useContext, useState, useEffect} from "react";

import {AppContext} from "../../store/context";
import {classNames} from "../../helper";
import cross from "../../static/icons/cross-operating.svg";

import styles from "./Operating.module.css";

export const ResponsiveOperatingModal = () => {
    const {context} = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen = context.modals.operating.state.open

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 400)
        }
    }, [isOpen])

    const onDismiss = () => {
        context.modals.operating.dispatch({type: 'close'});
    };


    return <div className={classNames(styles.modal, (open ? styles.modalOpen : ''))}
                style={{display: display ? "flex" : "none"}}>
        <div className={styles.cross} onClick={() => onDismiss()}>
            <img src={cross} alt=""/>
        </div>
        <div className={styles.inner}>
            <div className={styles.title}>
                <div className={styles.titleIcon}/>
                <div className={styles.titleText}>сейчас закрыты...</div>
            </div>
            <div className={styles.operating}>Мы принимаем заказы ежедневно с 11:00 до 22:30</div>
            <div className={styles.text}>
                <div className={styles.disclamer}>
                    Заказы, оформленные в нерабочее время, будут обработаны после открытия.
                    В нерабочее время онлайн оплата не принимается
                </div>
                <div className={styles.thank}>Спасибо за понимание!</div>
            </div>
        </div>
    </div>
}